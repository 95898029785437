import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { fontSize, height, styled } from "@mui/system";
// import CustomizedDashboardNavbar from "../../../components/Custom/CustomizedDashboardNavbar";
import CustomizedButton from "../components/Custom/CustomizedButton";
import CustomizedSelect from "../components/Custom/CustomizedSelect";
import CustomizedCheckboxes from "../components/Custom/CustomizedCheckbox";
import { ISelectOption } from "../types/global";
import CustomizedTextField from "../components/Custom/CustomizedTextField";

interface BarcodeItem {
  productBarcode: string;
  productId: string;
  productName: string;
  productDescription: string;
  productSn: string;
  productLot: string;
  productQty: number;
  productUom: string;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#262B32" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 4,
}));

const typeOptions: ISelectOption[] = [
  {
    label: "A4 (5 แถว แถวละ 2 ดวง)",
    value: "template_A4",
  },
  {
    label: "Label 10x5ซม.",
    value: "template_label_10x5_cm",
  },
  {
    label: "Label 6x4นิ้ว",
    value: "template_label_6x4_inch",
  },
  {
    label: "Label 7x4.7ซม. DAYUN",
    value: "template_label_7x4_7_cm",
  },
  // {
  //   label: "A4 แนวตั้ง (5 แถว แถวละ 2 ดวง)",
  //   value: "template_A4_1",
  // },
  // {
  //   label: "Label 10x5ซม. (แนวตั้ง)",
  //   value: "template_label_10x5_cm_1",
  // },
  // {
  //   label: "Label 10x5ซม. (แนวนอน)",
  //   value: "template_label_10x5_cm_2",
  // },
  // {
  //   label: "Label 6x4นิ้ว (แนวตั้ง)",
  //   value: "template_label_6x4_inch_1",
  // },
  // {
  //   label: "Label 6x4นิ้ว (แนวนอน)",
  //   value: "template_label_6x4_inch_2",
  // },
  // {
  //   label: "Label 7x4.7ซม. DAYUN (แนวตั้ง)",
  //   value: "template_label_DAYUN_7x4_7_cm_1",
  // },
  // {
  //   label: "Label 7x4.7ซม. DAYUN (แนวนอน)",
  //   value: "template_label_DAYUN_7x4_7_cm_2",
  // },
];

interface TemplateConfig {
  [key: string]: {
    name: string;
    unit: string;
    width: string;
    height: string;
    pageHeight: string;
    qrCodeSize: number;
    column: number;
    rowsPerPage: number;
    labelsPerRow: number;
    padding: string;
  };
}

const templateConfig: TemplateConfig = {
  template_A4: {
    name: "template_A4",
    unit: "cm",
    width: "21",
    height: "5",
    pageHeight: "29.7",
    qrCodeSize: 90,
    column: 2,
    rowsPerPage: 5,
    labelsPerRow: 2,
    padding: "2mm",
  },
  template_label_10x5_cm: {
    name: "template_label_10x5_cm",
    unit: "cm",
    width: "10",
    height: "5",
    pageHeight: "5",
    qrCodeSize: 90,
    column: 1,
    rowsPerPage: 1,
    labelsPerRow: 1,
    padding: "0",
  },
  template_label_7x4_7_cm: {
    name: "template_label_7x4_7_cm",
    unit: "cm",
    width: "7",
    height: "4.7",
    pageHeight: "4.7",
    qrCodeSize: 60,
    column: 1,
    rowsPerPage: 1,
    labelsPerRow: 1,
    padding: "0",
  },
  template_label_6x4_inch: {
    name: "template_label_6x4_inch",
    unit: "in",
    width: "6",
    height: "4",
    pageHeight: "4",
    qrCodeSize: 90,
    column: 1,
    rowsPerPage: 1,
    labelsPerRow: 1,
    padding: "0",
  },
};

const typeMarginOptions: ISelectOption[] = [
  {
    label: "0px",
    value: "0px",
  },
  {
    label: "5px",
    value: "5px",
  },
  {
    label: "10px",
    value: "10px",
  },
  {
    label: "15px",
    value: "15px",
  },
  {
    label: "20px",
    value: "20px",
  },
  {
    label: "25px",
    value: "25px",
  },
  {
    label: "30px",
    value: "30px",
  },
];

const typeRotateOptions: ISelectOption[] = [
  {
    label: "0°",
    value: "0",
  },
  {
    label: "90°",
    value: "90",
  },
  {
    label: "180°",
    value: "180",
  },
  {
    label: "270°",
    value: "270",
  },
];

const typeScaleOptions: ISelectOption[] = [
  ...Array.from({ length: 20 }, (_, i) => ({
    label: `${((i + 1) * 10).toString()}%`,
    value: ((i + 1) * 10).toString(),
  })),
];

const infoStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  color: "black",
  flexDirection: "column",
  overflow: "hidden",
};

// const infoItemStyle: React.CSSProperties = {
//   marginBottom: "5px",
// };

const qrStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginRight: "10px",
  marginLeft: "5px",
};

const pageBreakStyle: React.CSSProperties = {
  pageBreakBefore: "always",
};

interface LabelProps {
  item: BarcodeItem;
  height: string;
  size: number;
  showQuantity: boolean;
  showLot: boolean;
}

const Label: React.FC<LabelProps> = React.memo(
  ({
    item,
    height = "5cm",
    size = 90,
    showLot = true,
    showQuantity = true,
  }) => {
    return (
      <div className="print-label-each" style={{ height: height }}>
        <div style={qrStyle}>
          <QRCode value={item.productBarcode} size={size} />
        </div>
        <div style={infoStyle}>
          <div className="info-item">
            <strong>รหัส: </strong> {item.productId}
          </div>
          <div className="info-item">
            <strong>ชื่อ: </strong> {item.productName}
          </div>
          {/* <div className="info-item">
          <strong>รายละเอียด:</strong> {item.productDescription}
        </div> */}
          {item.productSn && (
            <div className="info-item">
              <div className="label-sn">
                <strong>SN: </strong> {item.productSn}
              </div>
            </div>
          )}
          {showLot && item.productLot && (
            <div className="info-item">
              <strong>Lot: </strong>{" "}
              {new Date(item.productLot).toLocaleDateString("en-GB")}
            </div>
          )}
          {showQuantity && (
            <div className="info-item">
              <strong>จำนวน: </strong> {item.productQty} {item.productUom}
            </div>
          )}
        </div>
      </div>
    );
  }
);

const PrintLabel: React.FC = () => {
  const [barcodeList, setBarcodeList] = useState<BarcodeItem[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [selectedTemplateBorder, setSelectedTemplateBorder] =
    useState<boolean>(true);
  const [selectedTemplateBorderRadius, setSelectedTemplateBorderRadius] =
    useState<boolean>(true);
  const [
    selectedTemplateHorizontalCenter,
    setSelectedTemplateHorizontalCenter,
  ] = useState<boolean>(true);
  const [selectedTemplateTopMargin, setSelectedTemplateTopMargin] =
    useState<string>("0px");
  const [selectedTemplateLeftMargin, setSelectedTemplateLeftMargin] =
    useState<string>("0px");
  const [selectedTemplateShowQuantity, setSelectedTemplateShowQuantity] =
    useState<boolean>(true);
  const [selectedTemplateShowLot, setSelectedTemplateShowLot] =
    useState<boolean>(true);
  const [selectedScale, setSelectedScale] = useState<string>("100");
  const [selectedRotate, setSelectedRotate] = useState<string>("0");

  const [configTemplate, setSelectedConfigTemplate] = useState<any>({});

  useEffect(() => {
    try {
      const lsState = localStorage.getItem("barcode-list");
      if (lsState) {
        const parsedList = JSON.parse(lsState) as BarcodeItem[];
        if (Array.isArray(parsedList)) {
          setBarcodeList(parsedList);
        } else {
          console.error("Invalid format in local storage for barcode-list");
          setBarcodeList([]);
        }
      } else {
        setBarcodeList([]);
      }
    } catch (error) {
      console.error("Error reading barcode-list from local storage:", error);
      setBarcodeList([]);
    }
  }, []);

  useEffect(() => {
    try {
      // Get value from local storage
      let templateState = localStorage.getItem("barcode-template");
      let templateBorderState = localStorage.getItem("barcode-template-border");
      let templateBorderRadiusState = localStorage.getItem(
        "barcode-template-radius"
      );
      let templateHorizontalCenterState = localStorage.getItem(
        "barcode-template-horizontal-center"
      );
      let templateTopMarginState = localStorage.getItem(
        "barcode-template-top-margin"
      );
      let templateLeftMarginState = localStorage.getItem(
        "barcode-template-left-margin"
      );
      let templateShowLot = localStorage.getItem("barcode-template-show-lot");
      let templateShowQuantity = localStorage.getItem(
        "barcode-template-show-quantity"
      );
      let templateRotate = localStorage.getItem("barcode-template-rotate");
      let templateScale = localStorage.getItem("barcode-template-scale");

      // Validate template state
      function isValidTemplateType(value: string): boolean {
        return typeOptions.some((option) => option.value === value);
      }
      if (!templateState || !isValidTemplateType(templateState)) {
        templateState = "template_A4";
        console.log("Template is not a valid");
      }
      setSelectedTemplate(templateState);
      let configTemplate = templateConfig[templateState];
      setSelectedConfigTemplate(configTemplate);
      setSelectedTemplateBorder(templateBorderState === "true" ? true : true);
      setSelectedTemplateBorderRadius(
        templateBorderRadiusState === "true" ? true : true
      );
      setSelectedTemplateHorizontalCenter(
        templateHorizontalCenterState === "true" ? true : false
      );
      setSelectedTemplateShowLot(templateShowLot === "true" ? true : false);
      setSelectedTemplateShowQuantity(
        templateShowQuantity === "true" ? true : false
      );

      // Validate template margin state
      function isValidTemplateMargin(value: string): boolean {
        return typeMarginOptions.some((option) => option.value === value);
      }
      if (
        !templateTopMarginState ||
        !isValidTemplateMargin(templateTopMarginState)
      ) {
        templateTopMarginState = "0px";
        console.log("templateTopMarginState is not a valid");
      }
      setSelectedTemplateTopMargin(templateTopMarginState);

      if (
        !templateLeftMarginState ||
        !isValidTemplateMargin(templateLeftMarginState)
      ) {
        templateLeftMarginState = "0px";
        console.log("templateLeftMarginState is not a valid");
      }
      setSelectedTemplateLeftMargin(templateLeftMarginState);

      // Validate template rotate state
      function isValidTemplateRotate(value: string): boolean {
        return typeRotateOptions.some((option) => option.value === value);
      }
      if (!templateRotate || !isValidTemplateRotate(templateRotate)) {
        templateRotate = "0px";
        console.log("templateRotate is not a valid");
      }
      setSelectedRotate(templateRotate);

      // Validate template scale state
      function isValidTemplateScale(value: string): boolean {
        return typeScaleOptions.some((option) => option.value === value);
      }
      if (!templateScale || !isValidTemplateScale(templateScale)) {
        templateScale = "100";
        console.log("templateScale is not a valid");
      }
      setSelectedScale(templateScale);
    } catch (error) {}
  }, []);

  const getPageRows = (
    marginTop: string,
    marginLeft: string,
    configTemplate: any
  ) => {
    let rowsPerPage = configTemplate ? configTemplate.rowsPerPage : 1;
    let labelsPerRow = configTemplate ? configTemplate.labelsPerRow : 1;

    const pageRows = [];

    for (let i = 0; i < barcodeList.length; i += labelsPerRow * rowsPerPage) {
      const pageItems = barcodeList.slice(i, i + labelsPerRow * rowsPerPage);

      const rowLabels = pageItems.map((item, index) => {
        let qrCodeSize = configTemplate ? configTemplate.qrCodeSize : 90;
        return (
          <Label
            key={index}
            height={`${configTemplate.height}${configTemplate.unit}`}
            item={item}
            size={qrCodeSize}
            showQuantity={selectedTemplateShowQuantity}
            showLot={selectedTemplateShowLot}
          />
        );
      });

      pageRows.push(
        <>
          <div
            key={i}
            style={{
              ...pageBreakStyle,
              marginTop: marginTop || "0px",
              marginLeft: marginLeft || "0px",
            }}
            className="print-label-page-break"
          >
            <div
              className={`row`}
              style={{
                transform: `rotate(${selectedRotate}deg) translate(0, ${
                  selectedRotate === "90"
                    ? `${configTemplate.width - configTemplate.pageHeight}${
                        configTemplate.unit
                      }`
                    : 0
                })`,
                width: `${configTemplate.width}${configTemplate.unit}`,

                minHeight: `${
                  selectedRotate === "90" || selectedRotate === "270"
                    ? `${configTemplate.width}${configTemplate.unit}`
                    : `${configTemplate.height}${configTemplate.unit}`
                }`,
                gridTemplateColumns: `repeat(${configTemplate.column}, 1fr)`,
                padding: `${
                  selectedTemplateBorder ? configTemplate.padding : 0
                }`,
              }}
            >
              {rowLabels}
            </div>
          </div>
          {i + 1 < barcodeList.length && (
            <div className="print-label-page-break-render">
              - - - - - - - - - - - - - - - - -
            </div>
          )}
        </>
      );
    }

    return pageRows;
  };

  const handlePrint = () => {
    window.print();
  };

  if (barcodeList.length === 0) {
    return <div>ไม่พบรายการ Label</div>;
  }

  return (
    <Box>
      <div className="print-label-navigator">
        <HideOnScroll>
          <AppBar
            sx={{ backgroundColor: "#fff", borderBottom: "2px solid #32418F" }}
          >
            <Toolbar>
              <Container>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={1}>
                    <Item>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "25px" }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            marginTop: 1.1,
                          }}
                        >
                          แสดง {barcodeList.length} รายการ
                        </Typography>
                      </Stack>
                    </Item>
                    <Item>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "5px" }}
                      >
                        <CustomizedSelect
                          label={"รูปแบบ"}
                          defaultValue={selectedTemplate}
                          options={typeOptions}
                          sx={{
                            width: 293,
                            height: 35,
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template",
                              e.target.value
                            );
                            setSelectedTemplate(e.target.value);
                            let configTemplate = e.target.value
                              ? templateConfig[e.target.value]
                              : null;
                            setSelectedConfigTemplate(configTemplate);
                          }}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "10px" }}
                      >
                        <CustomizedSelect
                          label={"ระยะบน"}
                          defaultValue={selectedTemplateTopMargin}
                          options={typeMarginOptions}
                          sx={{
                            width: 90,
                            height: 35,
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-top-margin",
                              e.target.value
                            );
                            setSelectedTemplateTopMargin(e.target.value);
                          }}
                        />
                        <CustomizedSelect
                          label={"ระยะซ้าย"}
                          defaultValue={selectedTemplateLeftMargin}
                          options={typeMarginOptions}
                          sx={{
                            width: 90,
                            height: 35,
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-left-margin",
                              e.target.value
                            );
                            setSelectedTemplateLeftMargin(e.target.value);
                          }}
                        />
                        <CustomizedSelect
                          label={"หมุน"}
                          defaultValue={selectedRotate}
                          options={typeRotateOptions}
                          sx={{
                            width: 90,
                            height: 35,
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-rotate",
                              e.target.value
                            );
                            setSelectedRotate(e.target.value);
                          }}
                        />
                        <CustomizedSelect
                          label={"ซูม"}
                          defaultValue={selectedScale}
                          options={typeScaleOptions}
                          sx={{
                            width: 90,
                            height: 35,
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-scale",
                              e.target.value
                            );
                            setSelectedScale(e.target.value);
                          }}
                        />
                      </Stack>
                    </Item>
                    <Item>
                      <Stack direction="row" spacing={1}>
                        <CustomizedCheckboxes
                          label="มีขอบ"
                          value={selectedTemplateBorder}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-border",
                              e.target.checked
                            );
                            setSelectedTemplateBorder(
                              e.target.checked || false
                            );
                          }}
                        />
                        <CustomizedCheckboxes
                          label="ขอบมล"
                          value={selectedTemplateBorderRadius}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-radius",
                              e.target.checked
                            );
                            setSelectedTemplateBorderRadius(
                              e.target.checked || false
                            );
                          }}
                        />
                        <CustomizedCheckboxes
                          label="กลางกระดาษ"
                          value={selectedTemplateHorizontalCenter}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-horizontal-center",
                              e.target.checked
                            );
                            setSelectedTemplateHorizontalCenter(
                              e.target.checked || false
                            );
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <CustomizedCheckboxes
                          label="แสดง Lot"
                          value={selectedTemplateShowLot}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-show-lot",
                              e.target.checked
                            );
                            setSelectedTemplateShowLot(
                              e.target.checked || false
                            );
                          }}
                        />
                        <CustomizedCheckboxes
                          label="แสดงจำนวน"
                          value={selectedTemplateShowQuantity}
                          onChange={(e: any) => {
                            localStorage.setItem(
                              "barcode-template-show-quantity",
                              e.target.checked
                            );
                            setSelectedTemplateShowQuantity(
                              e.target.checked || false
                            );
                          }}
                        />
                      </Stack>
                    </Item>
                    <Item>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginTop: "25px" }}
                      >
                        <CustomizedButton
                          variant="contained"
                          title="พิมพ์ Label"
                          onClick={handlePrint}
                        />
                      </Stack>
                    </Item>
                  </Stack>
                </Box>
              </Container>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
      </div>

      <div
        className="print-label-wrapper"
        style={{
          transform: `scale(${parseInt(selectedScale) / 100})`,
          transformOrigin: "top left",
          width: `${(100 / parseInt(selectedScale)) * 100}%`,
          height: `${(100 / parseInt(selectedScale)) * 100}%`,
        }}
      >
        <div
          style={{
            width: `${
              selectedRotate === "90" || selectedRotate === "270"
                ? configTemplate.pageHeight
                : configTemplate.width
            }${configTemplate.unit}`,
            minHeight: `${
              selectedRotate === "90" || selectedRotate === "270"
                ? configTemplate.width
                : configTemplate.pageHeight
            }${configTemplate.unit}`,
          }}
          className={`
            ${
              configTemplate.name === "template_label_7x4_7_cm"
                ? "template_custom_dayun"
                : ""
            }${selectedTemplate === "template_A4_1" ? "template_A4_1" : ""}${
            selectedTemplate === "template_label_10x5_cm_1"
              ? "template_label_10x5_cm_1"
              : " "
          } ${
            selectedTemplate === "template_label_10x5_cm_2"
              ? "template_label_10x5_cm_2"
              : " "
          }${
            selectedTemplate === "template_label_6x4_inch_1"
              ? "template_label_6x4_inch_1"
              : " "
          } ${
            selectedTemplate === "template_label_6x4_inch_2"
              ? "template_label_6x4_inch_2"
              : " "
          } ${
            selectedTemplate === "template_label_DAYUN_7x4_7_cm_1"
              ? "template_label_DAYUN_7x4_7_cm_1"
              : " "
          } ${
            selectedTemplate === "template_label_DAYUN_7x4_7_cm_2"
              ? "template_label_DAYUN_7x4_7_cm_2"
              : " "
          } 
        ${selectedTemplateBorder ? "print-label-border" : " "}
        ${selectedTemplateBorderRadius ? "print-label-border-radius" : " "} 
        ${
          selectedTemplateHorizontalCenter
            ? "print-label-horizontal-center"
            : " "
        } 
        print-label-default-margin`}
        >
          {getPageRows(
            selectedTemplateTopMargin,
            selectedTemplateLeftMargin,
            configTemplate
          )}
        </div>
      </div>
    </Box>
  );
};

export default PrintLabel;
